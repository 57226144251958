import './App.css';

function App() {
  return (

      <header className="hero">
        <div className="bg-wrapper">
          <h1>
            Mikko Alavuo
          </h1>
          <h2>
            Full Stack Web Developer
          </h2>
          <a
            className="link"
            href="mailto:mikko@alavuo.fi"
            rel="noopener noreferrer"
          >mikko@alavuo.fi
          </a>
          <a
            className="link"
            href="tel:+358407449823"
            rel="noopener noreferrer"
          >040-744 9823
          </a>
        </div>
      </header>

  );
}

export default App;
